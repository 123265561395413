<template>
  <b-container>
    <b-row style="margin-top:10px;margin-bottom:10px;">
      <b-col cols="2" style="align-self: center;">
        <span>{{$t('Option')}} {{optionNumber}}</span>
      </b-col>
      <b-col cols="9">
        <ValidationProvider :name="$t('Question')" rules="required" v-slot="{ errors }">
          <b-form-input v-model="option.optionText"
                        :class="(errors.length > 0 ? ' is-invalid' : '')"
                        type="text" le required></b-form-input>
          <b-form-invalid-feedback> {{ errors[0] }} </b-form-invalid-feedback>
        </ValidationProvider>
      </b-col>
      <b-col cols="1">
        <b-button v-if="optionNumber!=1" @click="DeleteOption()" variant="danger"><font-awesome-icon icon="fa fa-times" /></b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  export default {
    name: 'CustomerFormTemplateQuestionOption',
    components: {

    },
    props: {
      option: Object,
      optionNumber: Number
    },
    data() {
      return {

      }
    },
    methods: {
      DeleteOption: function () {
        this.$emit("DeleteOption", this.option);
      }
    },
    mounted() {

    }
  }
</script>
